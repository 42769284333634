import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import cartReducer from "../features/shopping/cart/cartSlice";
import promotionsReducer from "../features/shopping/promotions/promotionsSlice";
import shippingRegionsReducer from "../features/shipping/shippingRegionsSlice";
import shippingZonesReducer from "../features/shipping/shippingZonesSlice";
import freeShippingMethodsReducer from "../features/shipping/shippingZonesFreeShippingMethodsSlice";
import ratesTablesMethodsReducer from "../features/shipping/shippingZonesRatesTablesMethodsSlice";
import sneakerFiltersReducer from "../features/shopping/sneakers/sneakerFiltersSlice";
import sneakersReducer from "../features/shopping/sneakers/sneakersSlice";
import sneakerSizesReducer from "../features/sneaker-sizes/sneakerSizesSlice";
import stockedSneakersReducer from "../features/shopping/stockProducts/stockedSneakersSlice";
import stockProductsReducer from "../features/shopping/stockProducts/stockProductsSlice";
import storesReducer from "../features/shopping/stores/storesSlice";
import userReducer from "../features/user/userSlice";
import userAccountReducer from "../features/accounts/userAccountSlice";
import userOrderItemReducer from "../features/accounts/urser-orders/userOrderItemSlice";
import userOrdersReducer from "../features/accounts/urser-orders/userOrdersSlice";
import userRequestsListReducer from "../features/accounts/user-returns/userRequestsListSlice";
import userReturnItemsReducer from "../features/accounts/user-returns/useReturnItemsSlice";
import usersReducer from "../features/accounts/usersSlice";
import selectedUserShippingAddressReducer from "../features/shipping/shippingAddressSlice";

// create a root reducer
const rootReducer = combineReducers({
  cart: cartReducer,
  freeShippingMethods: freeShippingMethodsReducer,
  promotions: promotionsReducer,
  ratesTablesMethods: ratesTablesMethodsReducer,
  selectedUserShippingAddress: selectedUserShippingAddressReducer,
  shippingRegions: shippingRegionsReducer,
  shippingZones: shippingZonesReducer,
  sneakerFilters: sneakerFiltersReducer,
  sneakers: sneakersReducer,
  sneakerSizes: sneakerSizesReducer,
  stockedSneakers: stockedSneakersReducer,
  stockProducts: stockProductsReducer,
  stores: storesReducer,
  user: userReducer,
  userAccount: userAccountReducer,
  userOrderItems: userOrderItemReducer,
  userOrders: userOrdersReducer,
  userRequestsList: userRequestsListReducer,
  userReturnItems: userReturnItemsReducer,
  users: usersReducer,
});

// redux persist configurations
const persistConfig = {
  key: "store",
  storage,
  whitelist: ["cart", "user"],
};

// create a persistedReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// create a store with the persistedReducer, ignoring some actions
const store = configureStore({
  reducer: persistedReducer,
  // exclude all the actions dispatched by redux-persist
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// persistor
export let persistor = persistStore(store);

export default store;
