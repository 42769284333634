import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const shippingRegionsAdapter = createEntityAdapter();
const initialState = shippingRegionsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchShippingRegions = createAsyncThunk(
  "shippingRegions/fetchShippingRegions",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/regions/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

const shippingRegionsSlice = createSlice({
  name: "shippingRegions",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingRegions.pending, (state, action) => {
        state.status = "lading";
      })
      .addCase(fetchShippingRegions.fulfilled, (state, action) => {
        state.status = "succeeded";
        shippingRegionsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchShippingRegions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllShippingRegions,
  selectById: selectShippingRegionById,
  selectIds: selectShippingRegionsIds,
} = shippingRegionsAdapter.getSelectors((state) => state.shippingRegions);

export const selectShippingPolicyRegionsIds = createSelector(
  [selectAllShippingRegions, (state, policyId) => policyId],
  (shippingRegions, policyId) => {
    const selectedShippingRegions = shippingRegions.filter((region) =>
      region.policies.some((id) => id === policyId)
    );
    const shippingPolicyRegionsIds = selectedShippingRegions.map(
      (region) => region.id
    );

    return shippingPolicyRegionsIds;
  }
);

export const selectShippingRegionByName = createSelector(
  [selectAllShippingRegions, (state, regionName) => regionName],
  (shippingRegions, regionName) =>
    shippingRegions.find((region) => region.region_name === regionName)
);

export default shippingRegionsSlice.reducer;
