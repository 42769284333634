import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";
import { selectAllCartItems } from "../cart/cartSlice";

const storesAdapter = createEntityAdapter();

const initialState = storesAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchStores = createAsyncThunk("stores/fetchStores", async () => {
  const response = await client.get(
    `${process.env.REACT_APP_API_ENDPOINT}/store-fronts/`,
    {
      credentials: "include",
    }
  );
  return response.data;
});

const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStores.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStores.fulfilled, (state, action) => {
        state.status = "succeeded";
        storesAdapter.addMany(state, action.payload);
      })
      .addCase(fetchStores.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// export the selectors
export const {
  selectAll: selectAllStoreFronts,
  selectById: selectStoreFrontById,
  selectIds: selectStoreFrontsIds,
} = storesAdapter.getSelectors((state) => state.stores);

// selector to return shoppedAtStores i.e stores of items in cart
export const selectShoppedAtStores = createSelector(
  selectAllStoreFronts,
  selectAllCartItems,
  (stores, cartItems) => {
    let neededStores = [];
    cartItems.forEach((item) => {
      if (stores.length > 0) {
        let store = stores.find((store) => store.id === item.store);
        neededStores.push(store.store_name);
      }
    });

    const shoppedAtStores = [...new Set(neededStores)];
    return shoppedAtStores;
  }
);

// export the reducer
export default storesSlice.reducer;
