import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const userReturnItemsAdapter = createEntityAdapter();
const initialState = userReturnItemsAdapter.getInitialState({
  status: "idle",
  singleStatus: "idle",
  error: null,
});

export const fetchUserReturnItems = createAsyncThunk(
  "userReturnItems/fetchUserReturnitems",
  async (requestId) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user/requests-for-return/${requestId}/return-items`,
      { credentials: "include" }
    );

    return response.data;
  }
);

export const updateReturnItemStatus = createAsyncThunk(
  "userReturnItems/updateReturnItemStatus",
  async ({ data, returnItemId }) => {
    const body = data;
    const response = await client.update(
      `${process.env.REACT_APP_API_ENDPOINT}/user/return-item/${returnItemId}/`,
      body,
      {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }
    );

    return response.data;
  }
);

const userReturnItemsSlice = createSlice({
  name: "userReturnitems",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserReturnItems.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserReturnItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        userReturnItemsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchUserReturnItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateReturnItemStatus.pending, (state, action) => {
        state.singleStatus = "updating return item";
      })
      .addCase(updateReturnItemStatus.fulfilled, (state, action) => {
        state.singleStatus = "update succeeded";
        userReturnItemsAdapter.upsertOne(state, action.payload);
      })
      .addCase(updateReturnItemStatus.rejected, (state, action) => {
        state.singleStatus = "update failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllUserReturnItems,
  selectById: selectUserReturnItemById,
  selectIds: selectUserReturnItemsIds,
} = userReturnItemsAdapter.getSelectors((state) => state.userReturnItems);

export const selectUserRequestForReturnReturnItemsIds = createSelector(
  selectAllUserReturnItems,
  (state, returnId) => returnId,
  (returnItems, returnId) => {
    const items = returnItems.filter(
      (returnItem) => returnItem.user_request_for_return.id === returnId
    );

    const returnItemsIds = items.map((item) => item.id);

    return returnItemsIds;
  }
);

export default userReturnItemsSlice.reducer;
