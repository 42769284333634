import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const userOrderItemsAdapter = createEntityAdapter();
const initialState = userOrderItemsAdapter.getInitialState({
  status: "idle",
  singleStatus: "idle",
  error: null,
});

export const fetchUserOrderItems = createAsyncThunk(
  "userOrderItems/fetchUserOrderItems",
  async (userOrderId) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user/orders/${userOrderId}/order-items/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

export const confirmDelivery = createAsyncThunk(
  "userOrderItems/confirmDelivery",
  async ({ data, orderItemId }) => {
    const body = data;
    const response = await client.update(
      `${process.env.REACT_APP_API_ENDPOINT}/user-order-item/${orderItemId}/`,
      body,
      {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }
    );

    return response.data;
  }
);

const userOrderItemsSlice = createSlice({
  name: "userOrderItems",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserOrderItems.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserOrderItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        userOrderItemsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchUserOrderItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(confirmDelivery.pending, (state, action) => {
        state.singleStatus = "confirming Delivery";
      })
      .addCase(confirmDelivery.fulfilled, (state, action) => {
        state.singleStatus = "Delivery confirmed";
        userOrderItemsAdapter.upsertOne(state, action.payload);
      })
      .addCase(confirmDelivery.rejected, (state, action) => {
        state.singleStatus = "Delivery not Confirmed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllUserOrderItems,
  selectById: selectUserOrderItemById,
  selectIds: selectUserOrderItemsIds,
} = userOrderItemsAdapter.getSelectors((state) => state.userOrderItems);

export const selectUserOrderOrderItemsIds = createSelector(
  selectAllUserOrderItems,
  (state, orderId) => orderId,
  (userOrderItems, orderId) => {
    const orderItems = userOrderItems.filter(
      (item) => item.user_order === orderId
    );

    const orderItemsIds = orderItems.map((item) => item.id);

    return orderItemsIds;
  }
);

export default userOrderItemsSlice.reducer;
