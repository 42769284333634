import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

import { client } from "../../../utilities/client/client";

// sneakers adapter
const sneakersAdapter = createEntityAdapter();

// sneakers initialState
const initialState = sneakersAdapter.getInitialState({
  status: "idle",
  singleStatus: "idle",
  error: null,
});

// fetchSneakers async thunk to get all sneakers
export const fetchSneakers = createAsyncThunk(
  "sneakers/fetchSneakers",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/sneakers/`,
      {
        credentials: "include",
      }
    );
    return response.data;
  }
);

// fetch specifitc sneaker async thunk
export const fetchSneaker = createAsyncThunk(
  "sneakers/fetchSneaker",
  async (sneakerId) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/sneakers/${sneakerId}/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

// define the slice
const sneakersSlice = createSlice({
  name: "sneakers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSneakers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSneakers.fulfilled, (state, action) => {
        state.status = "succeeded";
        sneakersAdapter.addMany(state, action.payload);
      })
      .addCase(fetchSneakers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSneaker.pending, (state, action) => {
        state.singleStatus = "loading";
      })
      .addCase(fetchSneaker.fulfilled, (state, action) => {
        state.singleStatus = "succeeded"; // change it to be set to succeeded
        sneakersAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchSneaker.rejected, (state, action) => {
        state.singleStatus = "failed";
        state.error = action.error.message;
      });
  },
});

// create the selectors
export const {
  selectAll: selectAllSneakers,
  selectById: selectSneakerById,
  selectIds: selectSneakerIds,
} = sneakersAdapter.getSelectors((state) => state.sneakers);

export const selectStockProductSneaker = createSelector(
  selectAllSneakers,
  (state, stockProductId) => stockProductId,
  (sneakers, stockProductId) =>
    sneakers.find(
      (sneaker) => sneaker.stock_product === parseInt(stockProductId)
    )
);

// export default the sneakersReducer from the sneakers Slice
export default sneakersSlice.reducer;
