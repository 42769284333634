import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./components/app/algolia-theme.css";
import "./index.css";
import App from "./components/app/App";
import Home from "./components/app/Home";
import reportWebVitals from "./reportWebVitals";

import store from "./components/app/store";
import { Provider } from "react-redux";

import SneakersLoader from "./components/utilities/loaders/SneakersLoader";

const Login = lazy(() => import("./components/authentication/Login"));
const Signup = lazy(() => import("./components/authentication/Signup"));
const EmailConfirmation = lazy(() =>
  import("./components/authentication/EmailConfirmation")
);
const ForgotPassword = lazy(() =>
  import("./components/authentication/ForgotPassword")
);
const ForgotPasswordEmailSent = lazy(() =>
  import("./components/authentication/ForgotPasswordEmailSent")
);
const ResetPassword = lazy(() =>
  import("./components/authentication/ResetPassword")
);
const SneakersRoutes = lazy(() =>
  import("./components/shopping/sneakers/SneakersRoutes")
);
const StoreFrontRoutes = lazy(() =>
  import("./components/shopping/store-front/StoreFrontRoutes")
);
const Cart = lazy(() => import("./components/shopping/cart/Cart"));
const CheckoutRoutes = lazy(() =>
  import("./components/shopping/checkout/CheckoutRoutes")
);

const AccountManagementRoutes = lazy(() =>
  import("./components/accounts/AccountManagementRoutes")
);
const TermsOfUse = lazy(() => import("./components/app/policies/TermsOfUse"));
const PrivacyPolicy = lazy(() =>
  import("./components/app/policies/PrivacyPolicy")
);
const ShippingPolicy = lazy(() =>
  import("./components/app/policies/ShippingPolicy")
);
const ReturnPolicy = lazy(() =>
  import("./components/app/policies/ReturnPolicy")
);
const SizesGuide = lazy(() => import("./components/app/guides/SizesGuide"));
const SellerRegistrationGuide = lazy(() =>
  import("./components/app/guides/SellerRegistrationGuide")
);
const Blog = lazy(() => import("./components/app/blog/Blog"));
const About = lazy(() => import("./components/app/About"));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route
            path="policies/terms-of-use"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <TermsOfUse />
              </Suspense>
            }
          />
          <Route
            path="policies/privacy-policy"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="policies/shipping-policy"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ShippingPolicy />
              </Suspense>
            }
          />
          <Route
            path="policies/return-policy"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ReturnPolicy />
              </Suspense>
            }
          />
          <Route
            path="guides/sizes-guide"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <SizesGuide />
              </Suspense>
            }
          />
          <Route
            path="guides/seller-registration-guide"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <SellerRegistrationGuide />
              </Suspense>
            }
          />
          <Route
            path="blog"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Blog />
              </Suspense>
            }
          />
          <Route
            path="about"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <About />
              </Suspense>
            }
          />
          <Route
            path="cart"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Cart />
              </Suspense>
            }
          />
          <Route
            path="sneakers/*"
            element={
              <Suspense fallback={<SneakersLoader />}>
                <SneakersRoutes />
              </Suspense>
            }
          />
          <Route
            path="store-fronts/:storeSlug/*"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <StoreFrontRoutes />
              </Suspense>
            }
          />
          <Route
            path="checkout/*"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <CheckoutRoutes />
              </Suspense>
            }
          />
          <Route
            path="account-management/:id/*"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <AccountManagementRoutes />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/login"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/signup"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Signup />
            </Suspense>
          }
        />
        <Route
          path="/confirm-email"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <EmailConfirmation />
            </Suspense>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="/forgot-password/email-sent"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ForgotPasswordEmailSent />
            </Suspense>
          }
        />
        <Route
          path="/reset-password/:uid/:token"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ResetPassword />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
