import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SearchBox } from "react-instantsearch-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ListItemAvatar } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { IconContext } from "react-icons";
import { IoBagHandleOutline, IoLogIn } from "react-icons/io5";
import { RiHome2Fill, RiLoginCircleFill } from "react-icons/ri";
import { IoMdCart, IoMdPricetag } from "react-icons/io";
import { VscPackage } from "react-icons/vsc";
import { MdManageAccounts } from "react-icons/md";
import { IoInformationCircle } from "react-icons/io5";
import { selectUser } from "../features/user/userSlice";
import { selectTotalCartItems } from "../features/shopping/cart/cartSlice";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  drawerMenuButton: {
    marginRight: theme.spacing(1),
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appBar: {
    height: "64px",
    [theme.breakpoints.up("md")]: {
      paddingTop: ".6rem",
      height: "80px",
    },
  },
  navHeader: {
    paddingTop: ".3125rem",
    paddingBottom: ".3125rem",
    marginLeft: "1rem",
    fontSize: "1.25rem",
    textDecoration: "none",
    whiteSpace: "nowrap",
    color: "rgba(0, 0, 0, .9)",
    // flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  navItem: {
    paddingRight: ".7rem",
    paddingLeft: ".7rem",
    display: "block",
    textDecoration: "none",
    color: "rgba(0, 0, 0, .55)",
  },
  drawerNavItem: {
    padding: ".3rem .5rem",
    display: "inline-block",
    width: "100%",
    textDecoration: "none",
    color: "rgba(0, 0, 0, .9)",
    alignItems: "center",
  },
  cartNavItem: {
    paddingRight: ".5rem",
    paddingLeft: ".5rem",
    textDecoration: "none",
    color: "rgba(0, 0, 0, .55)",
  },
  sectionDesktop: {
    display: "none",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  list: {
    width: 250,
  },
  active: {
    backgroundColor: "rgb(36, 132, 198, 0.15)",
    borderRadius: "6px",
    color: "#1d60d1",
    fontWeight: "bold",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: ".7rem",
    },
    "&:hover": {
      color: "#1d60d1",
    },
  },
  rounded: {
    width: 30,
    height: 30,
    backgroundColor: "rgba(36, 132, 198, 0.15)",
  },
  listItemAvatarRoot: {
    minWidth: "40px",
  },
}));

const MainNavbar = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const classes = useStyles();

  const user = useSelector((state) => selectUser(state));
  const totalCartItems = useSelector(selectTotalCartItems);

  return (
    <nav className={classes.grow}>
      <AppBar
        classes={{ root: classes.appBar }}
        position="fixed"
        color="default"
      >
        <div className="container-lg p-0">
          <Toolbar>
            <>
              <IconButton
                edge="start"
                className={classes.drawerMenuButton}
                color="inherit"
                aria-label="open menu drawer"
                onClick={() => setShowDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
              >
                <div className={classes.list} role="presentation">
                  <List>
                    <IconContext.Provider
                      value={{ color: "#1d60d1", padding: "1.2em" }}
                    >
                      <ListItem button>
                        <ListItemText primary="Kicstoms" />
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <ListItemAvatar
                          classes={{ root: classes.listItemAvatarRoot }}
                        >
                          <Avatar variant="rounded" className={classes.rounded}>
                            <RiHome2Fill />
                          </Avatar>
                        </ListItemAvatar>
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? `${classes.drawerNavItem} ${classes.active}`
                              : `${classes.drawerNavItem}`
                          }
                          to="/"
                        >
                          Home
                        </NavLink>
                      </ListItem>
                      <ListItem button>
                        <ListItemAvatar
                          classes={{ root: classes.listItemAvatarRoot }}
                        >
                          <Avatar variant="rounded" className={classes.rounded}>
                            <IoInformationCircle />
                          </Avatar>
                        </ListItemAvatar>
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? `${classes.drawerNavItem} ${classes.active}`
                              : `${classes.drawerNavItem}`
                          }
                          to="/about"
                        >
                          About
                        </NavLink>
                      </ListItem>
                      <ListItem button>
                        <ListItemAvatar
                          classes={{ root: classes.listItemAvatarRoot }}
                        >
                          <Avatar variant="rounded" className={classes.rounded}>
                            <IoMdCart />
                          </Avatar>
                        </ListItemAvatar>
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? `${classes.drawerNavItem} ${classes.active}`
                              : `${classes.drawerNavItem}`
                          }
                          to="sneakers"
                        >
                          Sneakers
                        </NavLink>
                      </ListItem>
                      {user && (
                        <>
                          <ListItem button>
                            <>
                              <ListItemAvatar
                                classes={{ root: classes.listItemAvatarRoot }}
                              >
                                <Avatar
                                  variant="rounded"
                                  className={classes.rounded}
                                >
                                  <VscPackage />
                                </Avatar>
                              </ListItemAvatar>
                              <NavLink
                                className={({ isActive }) =>
                                  isActive
                                    ? `${classes.drawerNavItem} ${classes.active}`
                                    : `${classes.drawerNavItem}`
                                }
                                to={`account-management/${user.userId}/orders`}
                              >
                                Returns & Orders
                              </NavLink>
                            </>
                          </ListItem>
                          <ListItem button>
                            <>
                              <ListItemAvatar
                                classes={{ root: classes.listItemAvatarRoot }}
                              >
                                <Avatar
                                  variant="rounded"
                                  className={classes.rounded}
                                >
                                  <MdManageAccounts />
                                </Avatar>
                              </ListItemAvatar>
                              <NavLink
                                className={({ isActive }) =>
                                  isActive
                                    ? `${classes.drawerNavItem} ${classes.active}`
                                    : `${classes.drawerNavItem}`
                                }
                                to={`/account-management/${user.id}`}
                              >
                                My Account
                              </NavLink>
                            </>
                          </ListItem>
                        </>
                      )}
                      <Divider />
                      {!user && (
                        <>
                          <ListItem button>
                            <ListItemAvatar
                              classes={{ root: classes.listItemAvatarRoot }}
                            >
                              <Avatar
                                variant="rounded"
                                className={classes.rounded}
                              >
                                <IoLogIn />
                              </Avatar>
                            </ListItemAvatar>
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? `${classes.drawerNavItem} ${classes.active}`
                                  : `${classes.drawerNavItem}`
                              }
                              to="/login"
                            >
                              Sign In
                            </NavLink>
                          </ListItem>
                          <ListItem button>
                            <ListItemAvatar
                              classes={{ root: classes.listItemAvatarRoot }}
                            >
                              <Avatar
                                variant="rounded"
                                className={classes.rounded}
                              >
                                <RiLoginCircleFill />
                              </Avatar>
                            </ListItemAvatar>
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? `${classes.drawerNavItem} ${classes.active}`
                                  : `${classes.drawerNavItem}`
                              }
                              to="/signup"
                            >
                              Sign Up
                            </NavLink>
                          </ListItem>
                        </>
                      )}
                      <ListItem button>
                        <ListItemAvatar
                          classes={{ root: classes.listItemAvatarRoot }}
                        >
                          <Avatar variant="rounded" className={classes.rounded}>
                            <IoMdPricetag />
                          </Avatar>
                        </ListItemAvatar>
                        <a
                          href="https://sellers.kicstoms.com"
                          className={classes.drawerNavItem}
                        >
                          Sell
                        </a>
                      </ListItem>
                    </IconContext.Provider>
                  </List>
                </div>
              </Drawer>
            </>
            {/* end of drawer */}

            <NavLink className={classes.navHeader} to="/">
              Kicstoms
            </NavLink>
            <SearchBox
              translations={{
                placeholder: "Sneakers, brands, ...",
              }}
              submit={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.67"
                    transform="translate(1 1)"
                  >
                    <circle cx="7.11" cy="7.11" r="7.11" />
                    <path d="M16 16l-3.87-3.87" />
                  </g>
                </svg>
              }
              searchAsYouType={false}
            />
            <div className={classes.sectionDesktop}>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${classes.navItem} ${classes.active}`
                    : `${classes.navItem}`
                }
                to="/sneakers"
              >
                Sneakers
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${classes.navItem} ${classes.active}`
                    : `${classes.navItem}`
                }
                to="/about"
              >
                About
              </NavLink>
              {!user ? (
                <>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? `${classes.navItem} ${classes.active}`
                        : `${classes.navItem}`
                    }
                    to="/login"
                  >
                    Sign In
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? `${classes.navItem} ${classes.active}`
                        : `${classes.navItem}`
                    }
                    to="/signup"
                  >
                    Sign Up
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? `${classes.navItem} ${classes.active}`
                        : `${classes.navItem}`
                    }
                    to={`/account-management/${user.id}/orders`}
                  >
                    Returns & Orders
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? `${classes.navItem} ${classes.active}`
                        : `${classes.navItem}`
                    }
                    to={`/account-management/${user.id}`}
                  >
                    My Account
                  </NavLink>
                </>
              )}
              <a
                href="https://sellers.kicstoms.com"
                className={classes.navItem}
              >
                Sell
              </a>
            </div>
            <NavLink className={classes.cartNavItem} to="/cart">
              <IconContext.Provider value={{ size: "2em" }}>
                <Badge badgeContent={totalCartItems} showZero color="secondary">
                  <IoBagHandleOutline />
                </Badge>
              </IconContext.Provider>
            </NavLink>
          </Toolbar>
        </div>
      </AppBar>
    </nav>
  );
};

export default MainNavbar;
