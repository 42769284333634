import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

import { selectAllSneakers } from "../sneakers/sneakersSlice";

import { client } from "../../../utilities/client/client";

const stockProductsAdapter = createEntityAdapter();

const initialState = stockProductsAdapter.getInitialState({
  status: "idle",
  singleStatus: "idle",
  error: null,
});

export const fetchStockProducts = createAsyncThunk(
  "stockProducts/fetchStockProducts",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/stock/products/clients/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

export const fetchStockProduct = createAsyncThunk(
  "stockProducts/fetchStockProduct",
  async (stockProductId) => {
    const response = await client.get(
      "http://localhost:8000/stock/products/client/" + stockProductId + "/",
      { credentials: "include" }
    );
    return response.data;
  }
);

const stockProductsSlice = createSlice({
  name: "stockProducts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStockProducts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStockProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        stockProductsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchStockProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchStockProduct.pending, (state, action) => {
        state.singleStatus = "loading";
      })
      .addCase(fetchStockProduct.fulfilled, (state, action) => {
        stockProductsAdapter.upsertOne(state, action.payload);
        state.singleStatus = "succeeded";
      })
      .addCase(fetchStockProduct.rejected, (state, action) => {
        state.singleStatus = "failed";
        state.error = action.error.message;
      });
  },
});

// export select
export const {
  selectAll: selectAllStockProducts,
  selectById: selectStockProductById,
  selectIds: selectStockProductsIds,
} = stockProductsAdapter.getSelectors((state) => state.stockProducts);

// selector to select filtered stockProducts
export const selectFilteredStockProducts = createSelector(
  selectAllStockProducts,
  selectAllSneakers,
  (state) => state.sneakerFilters,
  (stockProducts, sneakers, sneakerFilters) => {
    const {
      brands,
      categories,
      priceRanges,
      styles,
      reviews,
      sizeGroups,
      sizesFilters,
    } = sneakerFilters;
    const showAllProducts =
      brands.length === 0 &&
      categories.length === 0 &&
      priceRanges.length === 0 &&
      styles.length === 0 &&
      reviews.length === 0 &&
      sizeGroups.length === 0 &&
      sizesFilters.length === 0;
    if (showAllProducts) {
      return stockProducts;
    }

    return stockProducts.filter((stockProduct) => {
      const sneaker = sneakers.find(
        (snkr) => snkr.stock_product === stockProduct.id
      );

      const brandMatches =
        brands.includes(sneaker.brand) || brands.length === 0;
      const styleMatches =
        styles.includes(sneaker.style) || styles.length === 0;
      const sizeGroupMatches =
        sizeGroups.includes(sneaker.size_group) || sizeGroups.length === 0;
      const categoryMatches =
        categories.includes(sneaker.category) || categories.length === 0;
      const priceMatches =
        priceRanges.includes(
          priceRanges.find(
            (range) =>
              parseInt(sneaker.price) >= range.min &&
              parseInt(sneaker.price) <= range.max
          )
        ) || priceRanges.length === 0;
      const reviewMatches =
        reviews.includes(
          reviews.find(
            (review) =>
              parseFloat(sneaker.rating) >= review.min &&
              parseFloat(sneaker.rating) <= review.max
          )
        ) || reviews.length === 0;
      // const sizesMatch = sizesFilters.includes(
      //   sneaker.associated_sizes.some(size => size.id)
      // ) || sizesFilters.length === 0
      const sizesMatch =
        sneaker.associated_sizes.some((size) => sizesFilters.includes(size)) ||
        sizesFilters.length === 0;

      return (
        brandMatches &&
        categoryMatches &&
        styleMatches &&
        priceMatches &&
        reviewMatches &&
        sizeGroupMatches &&
        sizesMatch
      );
    });
  }
);

export const selectFilteredStockProductsIds = createSelector(
  selectFilteredStockProducts,
  (filteredStockProducts) =>
    filteredStockProducts.map((stockProduct) => stockProduct.id)
);

// export the reducer
export default stockProductsSlice.reducer;
