import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { IconContext } from "react-icons";
import { makeStyles } from "@material-ui/core/styles";
import welcomeImage from "../../assets/undraw_add_to_cart_re_wrdo.svg";
import qualityImage from "../../assets/undraw_for_review_eqxk.svg";
import paymentsImage from "../../assets/undraw_pay_online_re_aqe6.svg";
import shippingImage from "../../assets/undraw_order_delivered_re_v4ab.svg";
import trackingImage from "../../assets/undraw_on_the_way_re_swjt.svg";
import supportImage from "../../assets/undraw_active_support_re_b7sj.svg";
import { selectAllStoreFronts } from "../features/shopping/stores/storesSlice";
import {
  SNEAKERSBRANDS,
  brandsFilterChanged,
} from "../features/shopping/sneakers/sneakerFiltersSlice";

const useStyles = makeStyles((theme) => ({
  welcome: {
    paddingTop: "4rem",
    height: "75vh",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "5rem",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "5rem",
    },
  },
  masonry: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridTemplateRows: "minmax(50px, auto)",
    columnGap: "1rem",
    rowGap: "1rem",
  },
  brandsMasonry: {
    gridColumn: "1 / 5",
    gridRow: "span 1",
  },
  storesMasonry: {
    gridColumn: "5 / 9",
    gridRow: "span 1",
  },
  signinMasonry: {
    gridColumn: "9 / -1",
    gridRow: "span 1",
  },
  masonryCards: {
    borderRadius: "15px",
  },
  searchBar: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  discoverCards: {
    height: "303px",
    borderRadius: "15px",
  },
  discoverCardsImages: {
    width: "50px",
    height: "50px",
  },
  [theme.breakpoints.down("md")]: {
    brandsMasonry: {
      gridColumn: "1 / -1",
      gridRow: "span 1",
    },
    storesMasonry: {
      gridColumn: "1 / -1",
      gridRow: "span 1",
    },
    signinMasonry: {
      gridColumn: "1 / -1",
      gridRow: "span 1",
    },
  },
}));

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storeFronts = useSelector(selectAllStoreFronts);

  const classes = useStyles();

  const handleBrandSearch = (brand, changeType = "added") => {
    dispatch(brandsFilterChanged(brand, changeType));
    navigate("sneakers");
  };

  return (
    <main>
      <div className={`container-fluid px-0 ${classes.welcome}`}>
        <div className={`container-xl py-lg-2 h-100`}>
          <div className={`row gx-5 gy-3 pt-2 pt-lg-5 pb-5 align-items-center`}>
            <div className="col-lg-6">
              <h1 className="display-2 text-start fw-bold text-primary">
                The marketplace for your favorite sneakers
              </h1>
              {/* <div className={`input-group ${classes.searchBar} w-75`}>
                <input className='form-control border-secondary border-end-0 rounded-start' type='search' placeholder='Search sneakers, brands...' aria-label='Search' aria-describedby='search-button' value={homeSearchTerms} onChange={(event) => setHomeSearchTerms(event.target.value)} />
                {homeSearchTerms && Object.entries(homeSearchTerms).length ? (
                  <Link to={`/search/${homeSearchTerms}`} id='search-button' className='btn btn-light bg-white border border-start-0 rounded-end'>
                    <FaSearch />
                  </Link>
                ):(
                  <button id='search-button' className='btn btn-light border-secondary border-start-0 bg-white' disabled>
                    <FaSearch />
                  </button>
                )}
              </div> */}
              <Link to={"sneakers"} className="btn btn-primary btn-lg mt-3">
                Shop Now
              </Link>
            </div>
            <div className="col-lg-6">
              <img src={welcomeImage} className="img-fluid" alt="welcome" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid brands-stores h-auto py-5 mt-2">
        <div className="container-xl px-0">
          {/* the shop by brand, favourite stores and sign in top section */}
          <div className={classes.masonry}>
            <div className={classes.brandsMasonry}>
              <div
                className={`card border-0 shadow bg-white h-100 ${classes.masonryCards}`}
              >
                <div className="card-body">
                  <h5 className="card-title text-center fw-bold">
                    Shop By Brand
                  </h5>
                  <div className="row row-cols-4 row-cols-md-4 g-2">
                    {SNEAKERSBRANDS.map((brand, index) => (
                      <div className="col" key={index}>
                        <button
                          onClick={() => handleBrandSearch(brand)}
                          className="btn btn-light btn-sm w-100"
                        >
                          {brand}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.storesMasonry}>
              <div
                className={`card border-0 shadow bg-white h-100 ${classes.masonryCards}`}
              >
                <div className="card-body">
                  <h5 className="card-title text-center fw-bold">
                    Your Favourite Stores
                  </h5>
                  {storeFronts && storeFronts.length ? (
                    <div className="row row-cols-4 row-cols-md-4 g-2">
                      {storeFronts.map((store, index) => (
                        <div className="col" key={index}>
                          <Link
                            to={`store-fronts/${store.slug}`}
                            className="btn btn-light btn-sm w-100"
                          >
                            {store.store_name}
                          </Link>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <p>
                        Give us a moment, We are getting you your favourite
                        sneaker stores
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.signinMasonry}>
              <div
                className={`card border-0 shadow bg-white h-100 ${classes.masonryCards}`}
              >
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    Sign In to get The Best Experience
                  </h5>
                  <div className="row my-3">
                    <div className="col-7 mx-auto">
                      <Link
                        to="/login"
                        className="btn btn-primary rounded border-0"
                      >
                        Sign in Securely
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---- end of shop by brand, favourite stores and sign in top section */}
          {/* the discover kicstoms section */}

          {/* ----------- end of discover kicstoms section ---------- */}
        </div>
      </div>
      <div className="container-fluid container-xl my-3 mt-5">
        <h5 className="text-center display-5">Discover Kicstoms</h5>
        <div id="discover-kicstoms" className="row row-cols-md-3 gy-3">
          <IconContext.Provider value={{ size: "5em", color: "#2484c6" }}>
            <div>
              <div className={`card ${classes.discoverCards}`}>
                <div className="card-body">
                  <div className="d-flex d-grid gap-3 ms-2 mb-3">
                    <img
                      className={`img-fluid ${classes.discoverCardsImages}`}
                      src={qualityImage}
                      alt="quality"
                    />
                    <p className="text-center fs-4">Quality Sneakers</p>
                  </div>
                  <p>
                    Bringing you{" "}
                    <span className="fw-bold text-primary">Quality</span>{" "}
                    Sneakers. We are Working and Will continue to work with
                    Prominent Sneaker Stores, Who already have the reputation
                    and trust of you, our{" "}
                    <span className="fw-bold text-primary">Customers</span>
                  </p>
                  <Link to="/sneakers" className="btn btn-sm btn-primary">
                    Shop
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <div className={`card ${classes.discoverCards}`}>
                <div className="card-body">
                  <div className="d-flex d-grid gap-3 ms-2 mb-3">
                    <img
                      className={`img-fluid ${classes.discoverCardsImages}`}
                      src={paymentsImage}
                      alt="mobile payments"
                    />
                    <p className="text-center fs-4">Secure Payments</p>
                  </div>
                  <p>
                    Payments Through our platform is Secure. You can pay with
                    Credit/Debit card and Via Mpesa. Receipts will be provided
                    right after payments.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className={`card ${classes.discoverCards}`}>
                <div className="card-body">
                  <div className="d-flex d-grid gap-3 ms-2 mb-3">
                    <img
                      className={`img-fluid ${classes.discoverCardsImages}`}
                      src={shippingImage}
                      alt="shipping"
                    />
                    <p className="text-center fs-4">Shipping Fees</p>
                  </div>
                  <p>
                    Shipping Fees are usually calculated in 2 places. At the{" "}
                    <strong>Cart</strong>, where this is is an estimate based on
                    the location of the user, and at the{" "}
                    <strong>Checkout page</strong> based on the shipping address
                    provided.
                  </p>
                  <Link
                    to="/policies/shipping-policy"
                    className="btn btn-sm btn-primary"
                  >
                    Shipping Policy
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <div className={`card ${classes.discoverCards}`}>
                <div className="card-body">
                  <div className="d-flex d-grid gap-3 ms-2 mb-3">
                    <img
                      className={`img-fluid ${classes.discoverCardsImages}`}
                      src={trackingImage}
                      alt="order tracking"
                    />
                    <p className="text-center fs-4">Track your Sneakers</p>
                  </div>
                  <p>
                    We Provide you with the ability to track your order
                    fulfillment process within the application. You will be able
                    to confirm delivery from the application itself.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className={`card ${classes.discoverCards}`}>
                <div className="card-body">
                  <div className="d-flex d-grid gap-3 ms-2 mb-3">
                    <img
                      className={`img-fluid ${classes.discoverCardsImages}`}
                      src={supportImage}
                      alt="customer support"
                    />
                    <p className="text-center fs-4">24/7 Customer Support</p>
                  </div>
                  <p>
                    We are always ready to serve. Reach out to us through social
                    media platforms, calls or email. We always answer.
                  </p>
                </div>
              </div>
            </div>
          </IconContext.Provider>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Home;
