import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const initialState = {
  loginStatus: "logged out",
  emailConfirmationStatus: "not confirmed",
  signupStatus: "not registered",
  loginError: null,
  signupError: null,
  user: null,
};

export const signIn = createAsyncThunk(
  "user/login",
  async ({ email, password }) => {
    const body = { email, password };
    const user = await client.post(
      `${process.env.REACT_APP_API_ENDPOINT}/login/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return user.data;
  }
);

export const signOut = createAsyncThunk("user/signout", async () => {
  const response = await client.get(
    `${process.env.REACT_APP_API_ENDPOINT}/logout/`,
    {
      credentials: "include",
    }
  );

  return response.data;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state, action) => {
        state.loginStatus = "logging in";
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loginStatus = "logged in";
        state.emailConfirmationStatus = "confirmed";
        state.signupStatus = "registered";
        state.user = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loginStatus = "failed login";
        state.loginError = action.error.message;
      })
      .addCase(signOut.pending, (state, action) => {
        state.loginStatus = "logging out";
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.loginStatus = "logged out";
        state.user = null;
      })
      .addCase(signOut.rejected, (state, action) => {
        state.loginError = "failed logout";
      });
  },
});

export const selectUser = createSelector(
  (state) => state.user,
  (user) => user.user
);

export default userSlice.reducer;
