import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const freeShippingMethodsAdapter = createEntityAdapter();

const initialState = freeShippingMethodsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchFreeShippingMethods = createAsyncThunk(
  "freeShippingMethods/fetchFreeShippingMethods",
  async (policyId) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/polices/${policyId}/free-shipping-methods/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

const freeShippingMethodsSlice = createSlice({
  name: "freeShippingMethods",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchFreeShippingMethods.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchFreeShippingMethods.fulfilled, (state, action) => {
        state.status = "succeeded";
        freeShippingMethodsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchFreeShippingMethods.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllFreeShippingMethods,
  selectById: selectFreeShippingMethodById,
  selectIds: selectFreeShippingMethodsIds,
} = freeShippingMethodsAdapter.getSelectors(
  (state) => state.freeShippingMethods
);

export const selectShippingZoneFreeShippingMethod = createSelector(
  [selectAllFreeShippingMethods, (state, zoneId) => zoneId],
  (freeShippingMethods, zoneId) =>
    freeShippingMethods.find((method) => method.shipping_zone === zoneId)
);

export default freeShippingMethodsSlice.reducer
