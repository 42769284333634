import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const shippingZonesAdapter = createEntityAdapter();
const initialState = shippingZonesAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchShippingZones = createAsyncThunk(
  "shippingZones/fetchShippingZones",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/zones`,
      { credentials: "include" }
    );

    return response.data;
  }
);

const shippingZonesSlice = createSlice({
  name: "shippingZones",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingZones.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchShippingZones.fulfilled, (state, action) => {
        state.status = "succeeded";
        shippingZonesAdapter.addMany(state, action.payload);
      })
      .addCase(fetchShippingZones.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllShippingZones,
  selectById: selectShippingZoneById,
  selectIds: selectShippingZonesIds,
} = shippingZonesAdapter.getSelectors((state) => state.shippingZones);

export const selectShippingRegionZones = createSelector(
  [selectAllShippingZones, (state, regionId) => regionId],
  (shippingZones, regionId) =>
    shippingZones.filter((zone) => zone.zone_region === regionId)
);

export const selectShippingZoneByName = createSelector(
  [selectAllShippingZones, (state, zoneName) => zoneName],
  (shippingZones, zoneName) =>
    shippingZones.find((zone) => zone.zone_name === zoneName)
);

export default shippingZonesSlice.reducer;
