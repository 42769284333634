import { createSelector, createSlice } from "@reduxjs/toolkit";

export const SNEAKERSBRANDS = [
  "Nike",
  "Adidas",
  "Converse",
  "Puma",
  "Reebok",
  "New Balance",
  "Fila",
  "Vans",
  "Under Armour",
];
export const SNEAKERSCATEGORIES = ["Basketball", "Running", "Streetwear"];
export const SNEAKERSSTYLES = ["High", "Mid", "Low"];
export const SNEAKERSSIZEGROUPS = ["Men's", "Women's", "Kid's", "Unisex"];
export const SNEAKERSPRICESRANGES = [
  { min: 0, max: 4500 },
  { min: 4500, max: 5500 },
  { min: 5500, max: 6500 },
  { min: 7500, max: 8500 },
  { min: 8500, max: 9500 },
  { min: 9500, max: 10500 },
  { min: 10500, max: 11500 },
  { min: 11500, max: 12500 },
  { min: 12500, max: 15000 },
];
export const SNEAKERSREVIEWSRANGES = [
  { min: 0.0, max: 1.0 },
  { min: 1.0, max: 2.0 },
  { min: 2.0, max: 3.0 },
  { min: 3.0, max: 4.0 },
  { min: 4.0, max: 5.0 },
];

const initialState = {
  brands: [],
  categories: [],
  priceRanges: [],
  styles: [],
  reviews: [],
  sizeGroups: [],
  sizesFilters: [],
};

const sneakerFiltersSlice = createSlice({
  name: "sneakerFilters",
  initialState,
  reducers: {
    brandsFilterChanged: {
      reducer(state, action) {
        let { brand, changeType } = action.payload;
        const { brands } = state;
        switch (changeType) {
          case "added": {
            if (!brands.includes(brand)) {
              brands.push(brand);
            }
            break;
          }

          case "removed": {
            state.brands = brands.filter(
              (existingBrand) => existingBrand !== brand
            );
            break;
          }
          default:
            return;
        }
      },
      prepare(brand, changeType) {
        return {
          payload: { brand, changeType },
        };
      },
    },

    categoriesFilterChanged: {
      reducer(state, action) {
        let { category, changeType } = action.payload;
        const { categories } = state;

        switch (changeType) {
          case "added": {
            if (!categories.includes(category)) {
              categories.push(category);
            }
            break;
          }

          case "removed": {
            state.categories = categories.filter(
              (existingCategory) => existingCategory !== category
            );
            break;
          }

          default:
            return;
        }
      },
      prepare(category, changeType) {
        return {
          payload: { category, changeType },
        };
      },
    },

    priceRangesFilterChanged: {
      reducer(state, action) {
        let { priceRange, changeType } = action.payload;
        const { priceRanges } = state;

        switch (changeType) {
          case "added": {
            if (
              !priceRanges.some(
                (range) =>
                  range.min === priceRange.min && range.max === priceRange.max
              )
            ) {
              priceRanges.push(priceRange);
            }
            break;
          }

          case "removed": {
            priceRanges.splice(
              priceRanges.findIndex(
                (range) =>
                  range.min === priceRange.min && range.max === priceRange.max
              ),
              1
            );
            state.priceRanges = priceRanges;
            break;
          }

          default:
            return;
        }
      },
      prepare(priceRange, changeType) {
        return {
          payload: { priceRange, changeType },
        };
      },
    },

    stylesFilterChanged: {
      reducer(state, action) {
        let { style, changeType } = action.payload;
        const { styles } = state;

        switch (changeType) {
          case "added": {
            if (!styles.includes(style)) {
              styles.push(style);
            }
            break;
          }

          case "removed": {
            state.styles = styles.filter(
              (existingStyle) => existingStyle !== style
            );
            break;
          }

          default:
            return;
        }
      },
      prepare(style, changeType) {
        return {
          payload: { style, changeType },
        };
      },
    },

    reviewsFilterChanged: {
      reducer(state, action) {
        let { review, changeType } = action.payload;
        const { reviews } = state;

        switch (changeType) {
          case "added": {
            if (!reviews.includes(review)) {
              reviews.push(review);
            }
            break;
          }

          case "removed": {
            reviews.splice(
              reviews.findIndex(
                (existingReview) =>
                  existingReview.min === review.min &&
                  existingReview.max === review.max
              ),
              1
            );

            state.reviews = reviews;
            break;
          }

          default:
            return;
        }
      },
      prepare(review, changeType) {
        return {
          payload: { review, changeType },
        };
      },
    },

    sizeGroupsFilterChanged: {
      reducer(state, action) {
        let { sizeGroup, changeType } = action.payload;
        const { sizeGroups } = state;

        switch (changeType) {
          case "added": {
            if (!sizeGroups.includes(sizeGroup)) {
              sizeGroups.push(sizeGroup);
            }
            break;
          }

          case "removed": {
            state.sizeGroups = sizeGroups.filter(
              (existingSizeGroup) => existingSizeGroup !== sizeGroup
            );
            break;
          }

          default:
            return;
        }
      },
      prepare(sizeGroup, changeType) {
        return {
          payload: { sizeGroup, changeType },
        };
      },
    },

    sizesFilterChanged: {
      reducer(state, action) {
        let { size, changeType } = action.payload;
        const { sizesFilters } = state;

        switch (changeType) {
          case "added": {
            if (!sizesFilters.includes(size)) {
              sizesFilters.push(size);
            }
            break;
          }

          case "removed": {
            state.sizesFilters = sizesFilters.filter(
              (existingSize) => existingSize !== size
            );
            break;
          }

          default:
            return;
        }
      },
      prepare(size, changeType) {
        return {
          payload: { size, changeType },
        };
      },
    },

    filtersCleared(state, action) {
      state.brands = [];
      state.categories = [];
      state.priceRanges = [];
      state.styles = [];
      state.reviews = [];
      state.sizeGroups = [];
      state.sizesFilters = [];
    },
  },
});

// export the actions
export const {
  brandsFilterChanged,
  categoriesFilterChanged,
  priceRangesFilterChanged,
  stylesFilterChanged,
  reviewsFilterChanged,
  sizeGroupsFilterChanged,
  sizesFilterChanged,
  filtersCleared,
} = sneakerFiltersSlice.actions;

export const selectBrandsFilters = createSelector(
  (state) => state.sneakerFilters,
  (filters) => filters.brands
);

export const selectCategoriesFilters = createSelector(
  (state) => state.sneakerFilters,
  (filters) => filters.categories
);

export const selectPricesRangesFilters = createSelector(
  (state) => state.sneakerFilters,
  (filters) => filters.priceRanges
);

export const selectStylesFilters = createSelector(
  (state) => state.sneakerFilters,
  (filters) => filters.styles
);

export const selectReviewsFilters = createSelector(
  (state) => state.sneakerFilters,
  (filters) => filters.reviews
);

export const selectSizeGroupsFilters = createSelector(
  (state) => state.sneakerFilters,
  (filters) => filters.sizeGroups
);

export const selectSizesFilters = createSelector(
  (state) => state.sneakerFilters,
  (filters) => filters.sizesFilters
);

export default sneakerFiltersSlice.reducer;
