import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const ratesTablesMethodsAdapter = createEntityAdapter();

const initialState = ratesTablesMethodsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchAllRatesTablesMethods = createAsyncThunk(
  "ratesTablesMethods/fetchAllRatesTablesMethods",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/rates-tables/all/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

const ratesTablesMethodsSlice = createSlice({
  name: "ratesTablesMethods",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRatesTablesMethods.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAllRatesTablesMethods.fulfilled, (state, action) => {
        state.status = "succeeded";
        ratesTablesMethodsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchAllRatesTablesMethods.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllShippingZonesRatesTables,
  selectById: selectShippingZoneRatesTableById,
  selectIds: selectShippingZonesRatesTablesIds,
} = ratesTablesMethodsAdapter.getSelectors((state) => state.ratesTablesMethods);

export const selectShippingZoneRatesTable = createSelector(
  [selectAllShippingZonesRatesTables, (state, zoneId) => zoneId],
  (ratesTables, zoneId) =>
    ratesTables.find((table) => table.shipping_zone === zoneId)
);

export default ratesTablesMethodsSlice.reducer;
