import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { MdCopyright } from "react-icons/md";
import { SiInstagram, SiFacebook, SiTwitter } from "react-icons/si";
import { HiLocationMarker, HiPhone, HiOutlineMail } from "react-icons/hi";

const Footer = (props) => {
  const [messageEmail, setMessageEmail] = useState("");
  const [messageSubject, setMessageSubject] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  const sendMessage = () => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/messages/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: messageEmail,
        subject: messageSubject,
        message: messageBody,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setMessageSent(true);
          setMessageEmail("");
          setMessageSubject("");
          setMessageBody("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container-fluid mt-4 border-top">
      <div className="container-lg pt-4 text-secondary">
        <div className="row">
          <div className="col-md-8">
            <div className="row gy-2 mb-4">
              <div className="col-md-4">
                <h6 className="fw-bold lh-lg">Policies</h6>
                <ul className="footer-list">
                  <li>
                    <a
                      href="https://policies.kicstoms.com/terms"
                      className="mb-1 footer-link"
                    >
                      Terms of use
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://policies.kicstoms.com/privacy"
                      className="mb-1 footer-link"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <Link
                      to="/policies/shipping-policy"
                      className="mb-1 footer-link"
                    >
                      Kicstoms Shipping Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/policies/return-policy"
                      className="mb-1 footer-link"
                    >
                      Return Policy
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://policies.kicstoms.com/refund"
                      className="mb-1 footer-link"
                    >
                      Refund Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <h6 className="fw-bold lh-lg">Guides</h6>
                <ul className="footer-list">
                  <li>
                    <Link to="/guides/sizes-guide" className="mb-1 footer-link">
                      Sizes Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/guides/seller-registration-guide"
                      className="mb-1 footer-link"
                    >
                      Seller Registration
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <h6 className="fw-bold lh-lg">Contacts</h6>
                <IconContext.Provider
                  value={{ color: "#2484c6", size: "1.2em" }}
                >
                  <p className="mb-1 text-primary">
                    <HiLocationMarker /> N&N House N13 Uzima Street, Hunters
                    Kasarani, Nairobi
                  </p>
                  <p className="mb-1 text-primary">
                    <HiPhone /> +254 706 304108
                  </p>
                  <p className="mb-1 text-primary">
                    <HiOutlineMail /> kicstoms@gmail.com
                  </p>
                </IconContext.Provider>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <h6 className="fw-bold lh-lg">Leave a Message</h6>
            <form>
              <div className="form-floating mb-2">
                <input
                  type="email"
                  name="contactEmail"
                  className="form-control footer-inputs border-primary text-secondary"
                  value={messageEmail}
                  onChange={(event) => setMessageEmail(event.target.value)}
                  placeholder="email"
                />
                <label htmlFor="contactEmail" className="form-label">
                  Your email
                </label>
              </div>
              <div className="form-floating mb-2">
                <input
                  type="email"
                  name="subject"
                  className="form-control footer-inputs border-primary text-secondary"
                  value={messageSubject}
                  onChange={(event) => setMessageSubject(event.target.value)}
                  placeholder="Subject"
                />
                <label htmlFor="subject" className="form-label">
                  Subject
                </label>
              </div>
              <div className="form-floating mb-2">
                <textarea
                  className="form-control h-100 footer-inputs border-primary text-secondary"
                  name="message"
                  rows="4"
                  value={messageBody}
                  onChange={(event) => setMessageBody(event.target.value)}
                  placeholder="your message"
                ></textarea>
                <label htmlFor="message" className="form-label">
                  Your message
                </label>
              </div>
              <button
                type="button"
                className="btn btn-sm text-primary border-primary rounded-pill"
                onClick={() => sendMessage()}
              >
                Send message
              </button>
            </form>
          </div>
        </div>

        <hr />
        <div className="row">
          <div className="col-md-4">
            <p>
              Copyright <MdCopyright /> 2021 Kicstoms Sneakers Ltd. All rights
              reserved
            </p>
          </div>
          <div className="col-md-8">
            <div className="d-grid d-flex gap-3">
              <p>Connect</p>
              <IconContext.Provider value={{ color: "#6c757d", size: "1.2em" }}>
                <SiInstagram />
                <SiFacebook />
                <SiTwitter />
              </IconContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
