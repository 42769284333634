import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";
import { selectAllUserRequestsForReturn } from "../user-returns/userRequestsListSlice";

const userOrdersAdapter = createEntityAdapter();
const initialState = userOrdersAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchUserOrders = createAsyncThunk(
  "userOrders/fetchUserOrders",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user-orders/`,
      {
        credentials: "include",
      }
    );

    return response.data;
  }
);

const userOrdersSlice = createSlice({
  name: "userOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserOrders.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        userOrdersAdapter.addMany(state, action.payload);
      })
      .addCase(fetchUserOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllUserOrders,
  selectById: selectUserOrderById,
  selectIds: selectUserOrdersIds,
} = userOrdersAdapter.getSelectors((state) => state.userOrders);

export const selectUserOrderItemReturnStatus = createSelector(
  selectAllUserRequestsForReturn,
  (state, orderItemId) => orderItemId,
  (userRequestsForReturn, orderItemId) => {
    try {
      const itemReturned = userRequestsForReturn.some((request) =>
        request.return_items.some((item) => item.order_item === orderItemId)
      );

      return itemReturned;
    } catch (error) {
      console.log(error);
    }
  }
);

export default userOrdersSlice.reducer;
