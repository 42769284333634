// function to be used for api calls, borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export async function client(endpoint, { body, ...customConfig } = {}) {
  const headers = {
    Accept: "application/json",
  };

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;
  try {
    const response = await fetch(endpoint, config);

    if (response.ok) {
      if (response.status === 204 || response.status === 205) {
        return {
          status: response.status,
          data: {},
        };
      }
      data = await response.json();
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    }
    throw new Error(response.statusText);
  } catch (error) {
    return Promise.reject(error.message ? error.message : data);
  }
}

// extra wrappers
client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "GET" });
};

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body });
};

client.update = function (endpoint, body, customConfig = {}) {
  return client(endpoint, {
    ...customConfig,
    body,
    method: "PUT",
    credentials: "include",
  });
};

client.delete = function (endpoint, customConfig = {}) {
  return client(endpoint, {
    ...customConfig,
    method: "DELETE",
    credentials: "include",
  });
};

client.patch = function (endpoint, body, customConfig = {}) {
  return client(endpoint, {
    ...customConfig,
    body,
    method: "PATCH",
    // credentials: 'include',
  });
};
