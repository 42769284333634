import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import algoliaSearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import qs from "qs";
import MainNavbar from "./MainNavbar";

import { fetchUsers } from "../features/accounts/usersSlice";
import { fetchSneakers } from "../features/shopping/sneakers/sneakersSlice";
import { fetchSneakerSizes } from "../features/sneaker-sizes/sneakerSizesSlice";
import { fetchStockProducts } from "../features/shopping/stockProducts/stockProductsSlice";
import { fetchStores } from "../features/shopping/stores/storesSlice";
import { fetchPromotions } from "../features/shopping/promotions/promotionsSlice";
import { fetchShippingRegions } from "../features/shipping/shippingRegionsSlice";
import { fetchShippingZones } from "../features/shipping/shippingZonesSlice";
import { persistor } from "../app/store";

const searchClient = algoliaSearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const createURL = (state) => `?${qs.stringify(state)}`;

const searchStateToURL = (location, searchState) => {
  if (searchState.query || searchState.range || searchState.refinementList) {
    if (location.state) {
      return `/sneakers/${createURL(searchState)}`;
    }
  }
  return `${location.pathname}`;
};

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // fetch the stores separately
  useEffect(() => {
    let isMounted = true;
    dispatch(fetchStores());

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    // fetch the safe users
    dispatch(fetchUsers());

    // fetch the stockproducts, sneakers, and sneakerSizes
    dispatch(fetchStockProducts());
    dispatch(fetchSneakers());
    dispatch(fetchSneakerSizes());
    dispatch(fetchPromotions());

    return () => false;
  }, [dispatch]);

  // fetch the policies related data
  useEffect(() => {
    let isMounted = true;
    // fetch shipping policies, regions and zones
    dispatch(fetchShippingRegions());
    dispatch(fetchShippingZones());

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const onSearchStateChange = (nextSearchState) => {
    navigate(searchStateToURL(location, nextSearchState), {
      state: nextSearchState,
    });
  };

  return (
    <PersistGate loading={null} persistor={persistor}>
      <InstantSearch
        searchClient={searchClient}
        indexName="kicstoms_Sneakers"
        createURL={createURL}
        onSearchStateChange={onSearchStateChange}
      >
        <MainNavbar />
        <Outlet />
      </InstantSearch>
    </PersistGate>
  );
};

export default App;
