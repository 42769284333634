import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const initialState = {
  status: "idle",
  userAccount: null,
  error: null,
};

export const fetchUserAccount = createAsyncThunk(
  "userAccount/fetchUserAccount",
  async (userId) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const userAccountSlice = createSlice({
  name: "userAccount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAccount.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAccount = action.payload;
      })
      .addCase(fetchUserAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectUserAccount = createSelector(
  (state) => state.userAccount,
  (userAccount) => userAccount.userAccount
);

export default userAccountSlice.reducer;
