import React from "react";
import { makeStyles } from "@material-ui/core";
import Footer from "../../app/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "4rem",
    [theme.breakpoints.up("sm")]: {
      marginTop: "5rem",
    },
  },
  filters: {
    display: "none",
    height: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "5px",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  filterTitlePlaceholder: {
    height: 20,
    backgroundColor: "#e0e0e0",
    width: "100%",
    borderRadius: 5,
  },
  filterButtonPlaceholder: {
    height: 20,
    backgroundColor: "#e0e0e0",
    width: "100%",
    marginRight: 5,
    borderRadius: 3,
  },
  filterHeaderPlaceholder: {
    height: 18,
    backgroundColor: "#e0e0e0",
    width: "100%",
    borderRadius: 5,
  },
  filterItemPlaceholder: {
    height: 14,
    backgroundColor: "#e0e0e0",
    width: "75%",
    borderRadius: 4,
  },
  sneakerImagePlaceholder: {
    height: 160,
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  shortDescriptionPlaceholder: {
    height: 20,
    backgroundColor: "#e0e0e0",
    width: "100%",
  },
  sneakerColorwayPlaceholder: {
    height: 10,
    backgroundColor: "#e0e0e0",
    width: "100%",
  },
  sneakerPricePlaceholder: {
    height: 20,
    backgroundColor: "#e0e0e0",
    width: "100%",
  },
  sneakerPromotionPlaceholder: {
    height: 30,
    backgroundColor: "#e0e0e0",
    width: "100%",
  },
  sneakerSizesPlaceholder: {
    height: 10,
    backgroundColor: "#e0e0e0",
    width: "100%",
  },
}));

const SneakersLoader = () => {
  const classes = useStyles();

  return (
    <main aria-hidden="true">
      <div className={`container-xxl ${classes.root}`}>
        <div className="row g-0">
          <div className="col-lg-2 border-end border-light">
            <div className="row pt-3 justify-content-between">
              <div className="col-6">
                <p className={`${classes.filterTitlePlaceholder}`}></p>
              </div>
              <div className="col-3">
                <p className={`${classes.filterButtonPlaceholder}`}></p>
              </div>
            </div>

            {/* the filters */}
            <div className={classes.filters}>
              <div>
                <p
                  className={`${classes.filterHeaderPlaceholder} mt-2 mb-2`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
              </div>
              <div>
                <p
                  className={`${classes.filterHeaderPlaceholder} mt-2 mb-2`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
              </div>
              <div>
                <p
                  className={`${classes.filterHeaderPlaceholder} mt-2 mb-2`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
              </div>
              <div>
                <p
                  className={`${classes.filterHeaderPlaceholder} mt-2 mb-2`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
              </div>
              <div>
                <p
                  className={`${classes.filterHeaderPlaceholder} mt-2 mb-2`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
              </div>
              <div>
                <p
                  className={`${classes.filterHeaderPlaceholder} mt-2 mb-2`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
              </div>
              <div>
                <p
                  className={`${classes.filterHeaderPlaceholder} mt-2 mb-2`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
                <p
                  className={`${classes.filterItemPlaceholder} text-end mb-1`}
                ></p>
              </div>
            </div>
            {/* end of filters */}
          </div>

          <div className="col-lg-10 px-1">
            <div
              className="row row-cols-2 row-cols-md-3 row-cols-lg-5 g-3 mx-nd-3"
              style={{ minHeight: "400px" }}
            >
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card" aria-hidden="true">
                  <div className={classes.sneakerImagePlaceholder}></div>
                  <div className="card-body">
                    <p
                      className={`card-title ${classes.shortDescriptionPlaceholder} mb-2`}
                    ></p>
                    <p
                      className={`${classes.sneakerColorwayPlaceholder} mb-2`}
                    ></p>
                    <div className="row gx-2 align-items-center">
                      <div className="col-7">
                        <p
                          className={`${classes.sneakerPricePlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerPromotionPlaceholder} rounded-pill mb-2`}
                        ></p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                      <div className="col-5">
                        <p
                          className={`${classes.sneakerSizesPlaceholder} mb-2`}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default SneakersLoader;
