import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { client } from "../../../utilities/client/client";

const promotionsAdapter = createEntityAdapter();

const initialState = promotionsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchPromotions = createAsyncThunk(
  "promotions/fetchPromotions",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/promotions/clients`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromotions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPromotions.fulfilled, (state, action) => {
        state.status = "succeeded";
        promotionsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchPromotions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// selectors
export const {
  selectAll: selectAllPromotions,
  selectById: selectPromotionById,
  selectIds: selectPromotionsIds,
  selectTotal: selectTotalPromotions,
} = promotionsAdapter.getSelectors((state) => state.promotions);

// export reducer
export default promotionsSlice.reducer;
