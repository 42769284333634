import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

import { client } from "../../../utilities/client/client";

// cart entity adapter
const cartAdapter = createEntityAdapter();

// initalState of the cart
const initialState = cartAdapter.getInitialState({
  checkoutStatus: "idle",
  error: null,
});

// order placement thunk to carry out the checkout functionality. Needs to be a little bit more complicated than this
export const placeOrder = createAsyncThunk("cart/placeOrder", async (data) => {
  const body = data;
  const response = await client.post(
    `${process.env.REACT_APP_API_ENDPOINT}/checkout/place-order/`,
    body,
    {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }
  );
  return response.data;
});

// define the slice
const cartSlice = createSlice({
  name: "cart",
  initialState,
  // addedToCart, removedFromCart, and clearedCart
  reducers: {
    addedToCart(state, action) {
      const item = action.payload;
      item.subTotal = item.price * item.pairs;
      cartAdapter.addOne(state, item);
    },
    updatedNumberOfPairs: {
      reducer(state, action) {
        const { cartItemId, pairs } = action.payload;
        const item = state.entities[cartItemId];
        item.pairs = pairs;
        item.subTotal = item.price * item.pairs;
      },
      prepare(cartItemId, pairs) {
        return {
          payload: { cartItemId, pairs },
        };
      },
    },
    removedFromCart: cartAdapter.removeOne,
    clearedCart: cartAdapter.removeAll,
    changedItemsShippingOption: {
      reducer(state, action) {
        const { policyId, option } = action.payload;
        const cartItems = Object.values(state.entities).filter(
          (item) => item.shippingPolicy === policyId
        );
        cartItems.map((item) => (item.shippingOption = option));
      },
      prepare(policyId, option) {
        return {
          payload: { policyId, option },
        };
      },
    },
    resetCheckoutStatus(state, action) {
      state.checkoutStatus = "idle";
      state.error = null;
    },
  },
  // checkout will be an extraReducer
  extraReducers: (builder) => {
    builder
      .addCase(placeOrder.pending, (state, action) => {
        state.checkoutStatus = "placing order";
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.checkoutStatus = "checked out";
        cartAdapter.removeAll(state);
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.checkoutStatus = "failed";
        state.error = action.error.message;
      });
  },
});

// export the actions
export const {
  addedToCart,
  updatedNumberOfPairs,
  removedFromCart,
  clearedCart,
  changedItemsShippingOption,
  resetCheckoutStatus,
} = cartSlice.actions;

// create the selectors
export const {
  selectAll: selectAllCartItems,
  selectById: selectCartItemById,
  selectIds: selectCartItemsIds,
  selectTotal: selectTotalCartItems,
} = cartAdapter.getSelectors((state) => state.cart);

// selector to return cartItemsSubTotals
export const selectCartItemsSubTotals = createSelector(
  selectAllCartItems,
  (cartItems) => {
    let subTotals = 0;
    cartItems.forEach((item) => {
      subTotals += item.subTotal;
    });

    return parseInt(subTotals);
  }
);

// export default the reducer
export default cartSlice.reducer;
