import { createSlice, createSelector } from "@reduxjs/toolkit";

// initial state to only have a selected address.
// selected address should only be one object and not more than one
const initialState = {
  selectedAddress: null,
};

// slice
const selectedUserShippingAddressSlice = createSlice({
  name: "selectedUserShippingAddress",
  initialState,
  reducers: {
    // action to choose an address.
    // used when providing an address for the first time, or
    // when choosing an address from already provided addresses
    selectedUserShippingAddress(state, action) {
      state.selectedAddress = action.payload;
    },
  },
});

// selector to return user selected shipping address.
// returns an address or empty object if no address is found
export const selectUserShippingAddress = createSelector(
  (state) => state.selectedUserShippingAddress,
  (addressObj) => {
    const { selectedAddress } = addressObj;
    if (selectedAddress) {
      return selectedAddress;
    }

    return {};
  }
);

// export actions
export const { selectedUserShippingAddress } =
  selectedUserShippingAddressSlice.actions;

// export the reducer
export default selectedUserShippingAddressSlice.reducer;
