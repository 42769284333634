import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const stockedSneakersAdapter = createEntityAdapter();

const initialState = stockedSneakersAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchStockedSneakers = createAsyncThunk(
  "stockedSneakers/fetchStockedSneakers",
  async (stockProductId) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/stock/stocked-sneakers/${stockProductId}/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

const stockedSneakersSlice = createSlice({
  name: "stockedSneakers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStockedSneakers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStockedSneakers.fulfilled, (state, action) => {
        state.status = "succeeded";
        stockedSneakersAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchStockedSneakers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// export selector
export const {
  selectAll: selectAllStockedSneakers,
  selectById: selectStockedSneakerById,
  selectIds: selectStockedSneakersIds,
} = stockedSneakersAdapter.getSelectors((state) => state.stockedSneakers);

export const selectStockProductStockedSneakers = createSelector(
  [selectAllStockedSneakers, (state, productId) => productId],
  (stockedSneakers, productId) =>
    stockedSneakers.filter(
      (sneaker) => sneaker.stock_product === parseInt(productId)
    )
);

// export the stockedSneakersReducers
export default stockedSneakersSlice.reducer;
