import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const userRequestsListAdapter = createEntityAdapter();

const initialState = userRequestsListAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchUserRequestsForReturn = createAsyncThunk(
  "userRequestsList/fetchUserRequestsForReturn",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user-requests-for-return/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

export const initiateRequestForReturn = createAsyncThunk(
  "userRequestsList/initiateRequestForReturn",
  async (data) => {
    const body = data;
    const response = await client.post(
      `${process.env.REACT_APP_API_ENDPOINT}/request-return/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

const userRequestsListSlice = createSlice({
  name: "userRequestsList",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRequestsForReturn.pending, (state, action) => {
        state.status = "loadin";
      })
      .addCase(fetchUserRequestsForReturn.fulfilled, (state, action) => {
        state.status = "succeeded";
        userRequestsListAdapter.addMany(state, action.payload);
      })
      .addCase(fetchUserRequestsForReturn.rejected, (state, action) => {
        state.status = "failed loading";
        state.error = action.error.message;
      })
      .addCase(initiateRequestForReturn.pending, (state, action) => {
        state.status = "requesting return";
      })
      .addCase(initiateRequestForReturn.fulfilled, (state, action) => {
        state.status = "request for return succeeded";
        userRequestsListAdapter.addOne(state, action.payload);
      })
      .addCase(initiateRequestForReturn.rejected, (state, action) => {
        state.status = "request for return failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllUserRequestsForReturn,
  selectById: selectUserRequestForReturnById,
  selectIds: selectUserRequestsForReturnIds,
} = userRequestsListAdapter.getSelectors((state) => state.userRequestsList);

export default userRequestsListSlice.reducer;
